import React, { useState } from "react";
import Layout from "../../organisms/Layout";
import BuyerAbout from "../../organisms/BuyerAbout";
import { PageSEO } from "../../atoms/SEO";
import BuyerCompanies from "../../new_organisms/Companies";
import CrowdStrike_logo from "../../images/buyer/companies/CrowdStrike_logo.svg";
import datadoghq from "../../images/buyer/companies/datadoghq-ar21.svg";
import Elastic from "../../images/buyer/companies/Elastic-NV.svg";
import aws from "../../images/buyer/companies/Amazon_Web_Services_Logo.svg";
import Google_Cloud_logo from "../../images/buyer/companies/Google_Cloud_logo.svg";
import Gupshup from "../../images/buyer/companies/Gupshup.svg";
import PaloAltoNetworks_2020_Logo from "../../images/buyer/companies/PaloAltoNetworks_2020_Logo.svg";
import snyk from "../../images/buyer/companies/snyk.svg";
import nova from "../../images/buyer/companies/nova.svg";
import snowflake from "../../images/buyer/companies/snowflake.svg";
import cockroachlabs from "../../images/buyer/companies/cockroach-labs.svg";
import sysdig from "../../images/buyer/companies/sysdig-inc-logo-vector.svg";
import mongodb from "../../images/buyer/companies/mongodb.svg";
import builder from "../../images/buyer/companies/builder.svg";
import salesforce from "../../images/buyer/companies/salesforce.svg";
import headerIllustrations from "../../images/saaspay-aws/header-illustration.svg";
import step1 from "../../images/saaspay-aws/step1.svg";
import step2 from "../../images/saaspay-aws/step2.svg";
import step3 from "../../images/saaspay-aws/step3.svg";
import feature1 from "../../images/saaspay-aws/feature1.svg";
import FAQs from "../../new_organisms/FAQs";
import Schema from "../../atoms/Schema";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import RevealAnimation from "../../atoms/RevealAnimation";
import Container from "../../atoms/Container";
import Steps from "../../new_organisms/Steps";
export default function Buyer({ location }: any) {
  const [showWaitListForm, setShowWaitListForm] = useState(false); //to keep track of visibility of modal

  function displayWaitListForm() {
    //display the wait-list modal/form by setting showWaitListFrom to true
    // setShowWaitListForm(true);
    window.open("https://form.jotform.com/240982279984475", "_blank");
  }

  function hideDisplayWaitListForm() {
    //hide the wait-list modal/form by setting showWaitListFrom to false
    setShowWaitListForm(false);
  }

  return (
    <Layout
      transparentNavbar={true}
      pathname={location.pathname}
      hideDisplayWaitListForm={hideDisplayWaitListForm}
      showWaitListForm={showWaitListForm}
      displayWaitListForm={displayWaitListForm}
    >
      <PageSEO
        path={location.pathname}
        title={
          "SaaSPay Buyer - Access One-Click Credit With Flexible Repayment"
        }
        description={
          "Powering Payments and possibilities. Access one-click credit to buy annual subscriptions, with flexible repayments."
        }
      />
      <Schema
        id={"buyer-page"}
        schema={{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Buyer",
              item: location.pathname,
            },
          ],
        }}
      />
      <svg
        className={"absolute top-0 left-0 w-full"}
        width="864"
        height="872"
        viewBox="0 0 864 872"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_bf_3716_7522)">
          <circle
            cx="164.557"
            cy="172.556"
            r="314.457"
            transform="rotate(-30 164.557 172.556)"
            fill="url(#paint0_linear_3716_7522)"
            fill-opacity="0.6"
          />
        </g>
        <defs>
          <filter
            id="filter0_bf_3716_7522"
            x="-533.953"
            y="-525.953"
            width="1397.02"
            height="1397.02"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="37.3949" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_3716_7522"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_3716_7522"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="192"
              result="effect2_foregroundBlur_3716_7522"
            />
          </filter>
          <linearGradient
            id="paint0_linear_3716_7522"
            x1="164.557"
            y1="-141.901"
            x2="164.557"
            y2="487.014"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5B2EBC" stop-opacity="0.5" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <div className={"relative"}>
        <svg
          className={"absolute right-0 bottom-0 w-full"}
          width="726"
          height="1074"
          viewBox="0 0 726 1074"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_bf_3716_7523)">
            <circle
              cx="699.13"
              cy="699.13"
              r="314.457"
              transform="rotate(-165 699.13 699.13)"
              fill="url(#paint0_linear_3716_7523)"
            />
          </g>
          <defs>
            <filter
              id="filter0_bf_3716_7523"
              x="0.594238"
              y="0.594727"
              width="1397.07"
              height="1397.07"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="37.3949" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3716_7523"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_3716_7523"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="192"
                result="effect2_foregroundBlur_3716_7523"
              />
            </filter>
            <linearGradient
              id="paint0_linear_3716_7523"
              x1="699.13"
              y1="384.672"
              x2="699.13"
              y2="1013.59"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5B2EBC" stop-opacity="0.5" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <Container className={"mt-24 relative"}>
          <h1
            className={
              "mx-auto relative max-w-4xl px-10 lg:text-6xl md:text-5xl text-4xl xl:text-7xl text-center leading-tight mb-9 gilroy-medium"
            }
          >
            <svg
              className={"absolute top-14 left-3 "}
              width="55"
              height="56"
              viewBox="0 0 55 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M54.33 25.49C29.32 25.49 29.16 8.6 27.5 1H26.94C25.29 8.6 26.01 25.49 1 25.49C1 25.49 22.63 26.74 26.94 54.32H27.5C31.81 26.74 54.33 25.49 54.33 25.49Z"
                fill="white"
                stroke="black"
                stroke-width="2"
                stroke-miterlimit="10"
              />
            </svg>
            <svg
              className={"absolute top-20 right-3 "}
              width="44"
              height="45"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42.6104 37.542C23.1427 21.8414 33.6213 8.59388 37.1002 1.63595L36.6643 1.2844C30.6089 6.1644 20.5662 19.7635 1.09852 4.06295C1.09852 4.06295 17.1505 18.6147 3.19149 42.7886L3.62739 43.1401C24.2962 24.3776 42.6104 37.542 42.6104 37.542Z"
                fill="white"
                stroke="black"
                stroke-width="2"
                stroke-miterlimit="10"
              />
            </svg>
            <svg
              className={"absolute top-20 left-52 lg:block hidden"}
              width="233"
              height="99"
              viewBox="0 0 233 99"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M179.923 2.25097C208.252 3.73005 227.947 11.4105 230.784 24.2865C235.68 46.4968 188.596 75.7513 125.626 89.627C62.6557 103.503 7.63772 96.7485 2.74479 74.533C-1.84272 53.711 39.251 26.7026 96.2935 11.9717"
                stroke="#A7A7C5"
                stroke-width="3"
                stroke-miterlimit="10"
              />
            </svg>

            <span className={"relative z-10"}>
              Unlock INR Transactions
              <br /> and Payments Flexibility for AWS Marketplace
            </span>
          </h1>

          <RevealAnimation
            animation={"slide-right"}
            className={
              "flex flex-wrap justify-center gap-4 md:gap-8 mb-14 md:mb-20 lg:mb-24"
            }
            delay={400}
          >
            <Button
              id={"buyer-about"}
              className={"!rounded-full "}
              onClick={displayWaitListForm}
              height={50}
              width={166}
              variant={"primary"}
            >
              <Text
                className={"!text-white !text-xs md:!text-sm"}
                textStyle={"inter-400-14-20"}
                text={"Join the Waitlist"}
              />
            </Button>
            <a
              href={"https://form.jotform.com/240982279984475"}
              target={"_blank"}
              className={
                "flex group transition-colors hover:md:border-p1 lg:pl-5 border border-transparent rounded-full justify-center md:justify-start items-center gap-2.5 w-full md:w-auto"
              }
            >
              <Text
                className={"!text-p1"}
                textStyle={"inter-400-14-20"}
                text={"Book a demo"}
              />
              <div
                className={
                  "rounded-full hidden md:flex items-center justify-center border border-p1 transition-colors group-hover:border-transparent h-9 w-9 lg:h-12 lg:w-12"
                }
              >
                <svg
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.75L15.25 7M15.25 7L10 12.25M15.25 7L1.75 7"
                    className={"stroke-p1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </RevealAnimation>
          <img src={headerIllustrations} className={"mx-auto mb-8 px-5"} />
        </Container>
      </div>
      <Container className={""}>
        <hr className={""} />
      </Container>

      <BuyerCompanies
        companies={[
          {
            logo: aws,
            url: "https://netflix.com",
          },
          {
            logo: mongodb,
            url: "https://netflix.com",
          },
          {
            logo: builder,
            url: "https://netflix.com",
          },
          {
            logo: salesforce,
            url: "https://netflix.com",
          },
          {
            logo: snyk,
            url: "https://netflix.com",
          },
          {
            logo: cockroachlabs,
            url: "https://netflix.com",
          },
          {
            logo: snowflake,
            url: "https://netflix.com",
          },
          {
            logo: Gupshup,
            url: "https://netflix.com",
          },
          {
            logo: Google_Cloud_logo,
            url: "https://netflix.com",
          },
          {
            logo: PaloAltoNetworks_2020_Logo,
            url: "https://netflix.com",
          },
          {
            logo: sysdig,
            url: "https://netflix.com",
          },
          {
            logo: Elastic,
            url: "https://netflix.com",
          },
          {
            logo: CrowdStrike_logo,
            url: "https://netflix.com",
          },
          {
            logo: nova,
            url: "https://netflix.com",
          },
          {
            logo: datadoghq,
            url: "https://netflix.com",
          },
        ]}
      />
      <Steps
        title={"*AWS Marketplace Subscriptions*"}
        subtitle={"Roadblocks to Revenue Realisation"}
        steps={[
          {
            title: "Lack of Payment Flexibility",
            body: "The rigid AWS Marketplace payments structure ties down the buying experience. Such complicated SaaS pricing for AWS makes it difficult to manage cash flow efficiently. Wish there was a customizable AWS payment solution that works for you...",
            logo: step1,
          },
          {
            title: "Complications in USD Payments",
            body: "Customers buying AWS Marketplace subscriptions are expected to make payments in USD. While the Reserve Bank of India’s regulations create complexities in cross-border transactions for them.",
            logo: step2,
          },
          {
            title: "Upfront Payments on Multi-Year EDP Commits",
            body: "AWS Marketplace billing offers a tight upfront payment on EDP commitments [12 / 24 / 36 months], worrying customers about their financial stability and strained resources.",
            logo: step3,
          },
        ]}
        variant={"stacked"}
      />
      <Steps
        title={"Introducing a *Customer-First AWS Payment Solution*"}
        subtitle={"The Ultimate ROI on Your AWS Subscription"}
        steps={[
          {
            title: "Multi-Currency",
            body: `
                    *Eliminate ACH Transfers*
                    Bid farewell to the hassles of ACH transfers. Our solution streamlines your transactions, making them smoother and more efficient.
                    ****
                    *Tax Complexities & Withholding Taxes*
                    Simplify tax management and avoid withholding taxes that can eat into your earnings.
                    `,
            logo: feature1,
          },
          {
            title: "Net90",
            body: `
                    *Adjust Working Capital Cycless*
                    Optimize your cash flow management by deferring payments for up to three months on AWS on 90-day invoice terms
                    ****
                    *Improve Cash Flow*
                    Invest in growth and innovation without immediate financial constraints with Net90 payments
                    `,
            logo: feature1,
          },
          {
            title: "Upfront 12/24/36",
            body: `
                    *Benefits of an Annual Subscription*
                    Enjoy the cost-saving advantages of an annual subscription without the commitment
                    ****
                    *Discounts Without Upfront Payments*
                   Access discounts of up to 57% on AWS services without the burden of upfront payments
                    `,
            logo: feature1,
          },
        ]}
        variant={"color-pop"}
      />
      <FAQs
        title={"*Frequently* asked Questions"}
        subtitle={"FAQs"}
        faqs={[
          {
            question: "What is the AWS marketplace?",
            answer:
              "AWS Marketplace is a digital catalog of software solutions, services, and products that are pre-configured for use on the Amazon Web Services (AWS) cloud platform. It offers a wide range of offerings from independent software vendors (ISVs) and service providers, making it easier for businesses to find, purchase, and deploy software and services directly within their AWS environment.",
          },
          {
            question: "How does pay later work in the AWS marketplace?",
            answer:
              "Pay Later in the AWS Marketplace allows you to access AWS services and pay back up for them on your own terms! This option provides flexibility in managing your cash flow, enabling you to use AWS services immediately while deferring payment for a more convenient time. It simplifies billing and can be a valuable resource for businesses looking to optimize their financial operations.",
          },
          {
            question: "How does net 90 payment work?",
            answer:
              "Net 90 payment terms mean that you have 90 days from the date of purchase to make the payment for the AWS services you've used. This extended payment period helps businesses improve their cash flow management by delaying the settlement of charges. It offers financial flexibility and allows you to invest in your business without immediate payment constraints.",
          },
          {
            question: "What are the advantages of AWS marketplace billing?",
            answer:
              "With the Flexible Payment Options, you can now choose from various payment methods, including Pay Later and Net 90, to match your financial needs. There is not any rigidity involved anymore, so you grow on your terms while enjoying the benefits of your favorite SaaS solutions.",
          },
          {
            question: "What are the 90-day invoice terms?",
            answer:
              "The 90-day invoice terms refer to the payment terms available in the AWS Marketplace, specifically in the Net 90 payment option. With this, you have 90 days to settle the invoice for AWS services you've used. This extended period provides greater flexibility for managing your budget and cash flow while still benefiting from AWS's cloud services.",
          },
        ]}
      />
    </Layout>
  );
}
