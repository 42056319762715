import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";
import RevealAnimation from "../../atoms/RevealAnimation";
import BoldSplitTitle from "../../atoms/BoldSplitTitle";
import Text from "../../atoms/Text";

function getPercentage(target: HTMLDivElement | null) {
  if (!target) return 0;
  return target.scrollTop / (target.scrollHeight - target.clientHeight);
}
const POP_COLORS = [
  "rgba(255,212,122,0.13)",
  "rgba(157,226,253,0.13)",
  "rgba(168,151,249,0.13)",
];
function Steps({
  title,
  subtitle,
  steps,
  variant = "primary",
}: {
  title: string;
  subtitle: string;
  steps: { logo: string; title: string; body: string }[];
  variant?: "primary" | "stacked" | "color-pop";
}) {
  const [scrollPercent, setScrollPercent] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (scrollPercent > 0 && scrollPercent < 0.2) {
      setStepIndex(Math.floor(steps.length * scrollPercent * 0.9));
    } else if (scrollPercent > 0.35 && scrollPercent < 0.6) {
      setStepIndex(Math.floor(steps.length * scrollPercent * 0.9));
    } else if (scrollPercent > 0.75 && scrollPercent < 0.9) {
      setStepIndex(Math.floor(steps.length * scrollPercent * 0.9));
    }
  }, [scrollPercent]);

  const step = steps[stepIndex];
  const topPadding = -250;
  useEffect(() => {
    if (variant === "primary") {
      if (scrollPercent === 1 || scrollPercent === 0) setScrollable(false);
      const listener = (e: any) => {
        const documentScrolledDistance =
          e.currentTarget.documentElement.scrollTop + topPadding;
        const sectionScrollDistance = parentRef.current?.offsetTop || 0;
        if (sectionScrollDistance)
          setScrollable(
            (documentScrolledDistance >= sectionScrollDistance &&
              scrollPercent !== 1) ||
              (documentScrolledDistance < sectionScrollDistance &&
                scrollPercent === 1)
          );
      };
      document.addEventListener("scroll", listener);
      return () => document.removeEventListener("scroll", listener);
    }
  }, [scrollPercent]);
  useEffect(() => {
    if (variant === "primary") {
      if (scrollable) {
        const documentScrolledDistance =
          document.documentElement.scrollTop + topPadding;
        const sectionScrollDistance = parentRef.current?.offsetTop || 0;
        document.body.classList.add("h-screen", "overflow-hidden");
        document.documentElement.scrollTo(
          0,
          sectionScrollDistance -
            topPadding -
            (documentScrolledDistance < sectionScrollDistance &&
            scrollPercent === 1
              ? 1
              : 0)
        );
      } else document.body.classList.remove("h-screen", "overflow-hidden");
    }
  }, [scrollable, scrollPercent]);
  const containerHeight =
    typeof window === "undefined"
      ? 0
      : document.getElementById("content-ref")?.offsetHeight;
  if (variant === "color-pop") {
    return (
      <Wrapper>
        <div className={"max-w-[1352px] mx-auto"}>
          <div className={"mb-3 md:mb-5 text-center"}>
            <h2 className={"max-w-2xl mx-auto"}>
              <BoldSplitTitle
                bold={(text) => (
                  <Text
                    className={
                      "text-4xl md:text-6xl gilroy-bold !leading-[1.1]"
                    }
                    text={text}
                  />
                )}
                normal={(text) => (
                  <Text
                    className={
                      "text-4xl md:text-6xl gilroy-regular !leading-[1.1]"
                    }
                    text={text}
                  />
                )}
                text={title}
              />
            </h2>
          </div>
          <div className={"mb-3 md:mb-7 text-center"}>
            <Text className={"text-2xl gilroy-regular"} text={subtitle} />
          </div>
          <div className={"flex flex-col mt-10 md:mt-20 gap-10"}>
            {steps.map((step, i) => (
              <div
                style={{ backgroundColor: POP_COLORS[i % POP_COLORS.length] }}
                className={
                  "flex flex-wrap-reverse items-center justify-between gap-10 p-10 lg:pt-24 lg:pl-24 lg:pb-36 lg:pr-36 rounded-[20px] w-full"
                }
              >
                <div className={"max-w-xl"}>
                  <div className={""}>
                    <Text
                      text={"0" + (i + 1).toString()}
                      className={
                        "gilroy-bold text-6xl md:text-7xl leading-normal text-[#DCDCDC]"
                      }
                    />
                  </div>
                  <div
                    className={
                      "text-3xl md:text-4xl lg:text-5xl gilroy-semibold mb-7"
                    }
                  >
                    {step.title}
                  </div>
                  <div>
                    <BoldSplitTitle
                      bold={(text) => (
                        <>
                          <Text
                            className={
                              "text-lg md:text-xl lg:text-[22px] font-semibold font-inter text-body2"
                            }
                            text={text}
                          />
                          <br />
                        </>
                      )}
                      normal={(text) => (
                        <Text
                          className={
                            "text-lg md:text-xl lg:text-[22px] font-inter text-body2"
                          }
                          text={text}
                        />
                      )}
                      text={step.body}
                    />
                  </div>
                </div>
                <div className={"md:pt-20"}>
                  <img src={step.logo} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    );
  }
  if (variant === "stacked") {
    return (
      <Wrapper>
        <div className={"max-w-[1240px] mx-auto py-20 md:py-32 lg:py-40"}>
          <div className={"mb-2.5 text-center"}>
            <h2 className={""}>
              <BoldSplitTitle
                bold={(text) => (
                  <Text
                    className={"text-4xl md:text-6xl gilroy-bold"}
                    text={text}
                  />
                )}
                normal={(text) => (
                  <Text
                    className={"text-4xl md:text-6xl gilroy-regular"}
                    text={text}
                  />
                )}
                text={title}
              />
            </h2>
          </div>
          <div className={"mb-3 md:mb-7 text-center"}>
            <Text
              className={"text-lg md:text-[44px] gilroy-regular"}
              text={subtitle}
            />
          </div>
          <div className={"flex flex-wrap mt-10 md:mt-20 lg:mt-36 gap-5"}>
            {steps.map((step, i) => (
              <div
                className={
                  "flex justify-between gap-12 pl-12 md:pl-16 bg-[#F0F1F5] rounded-[20px] pb-8 pt-[52px] flex-wrap-reverse  lg:w-[calc(50%-10px)] pr-7 " +
                  (i
                    ? ""
                    : "md:pr-0 md:flex-nowrap md:pt-32 md:pb-24 md:!w-full")
                }
              >
                <div className={"pl-2"}>
                  <div
                    className={"text-2xl md:text-[32px] gilroy-bold relative"}
                  >
                    <div className={"absolute top-0 left-0 -translate-x-full"}>
                      {i + 1}.&nbsp;
                    </div>
                    {step.title}
                  </div>
                  <p
                    className={
                      " max-w-xl text-lg md:text-xl lg:text-[22px] gilroy-regular mt-1.5"
                    }
                  >
                    {step.body}
                  </p>
                  {!i && (
                    <svg
                      className={"mt-8 hidden lg:block"}
                      width="46"
                      height="64"
                      viewBox="0 0 46 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26 3C26 1.34315 24.6569 -7.24234e-08 23 0C21.3431 7.24234e-08 20 1.34315 20 3L26 3ZM20.8787 63.1213C22.0503 64.2929 23.9498 64.2929 25.1213 63.1213L44.2132 44.0294C45.3848 42.8579 45.3848 40.9584 44.2132 39.7868C43.0416 38.6152 41.1421 38.6152 39.9706 39.7868L23 56.7574L6.02944 39.7868C4.85787 38.6152 2.95837 38.6152 1.7868 39.7868C0.615225 40.9584 0.615225 42.8579 1.7868 44.0294L20.8787 63.1213ZM20 3L20 61L26 61L26 3L20 3Z"
                        fill="#323B4B"
                        fill-opacity="0.08"
                      />
                    </svg>
                  )}
                </div>
                <div className={"items-center flex"}>
                  <img className={"shrink min-w-0"} src={step.logo} />
                  {(i - 1) % 3 === 0 && (
                    <svg
                      className={"shrink-0 hidden lg:block"}
                      width="64"
                      height="46"
                      viewBox="0 0 64 46"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 20C1.34315 20 0 21.3431 0 23C0 24.6569 1.34315 26 3 26V20ZM63.1213 25.1213C64.2929 23.9497 64.2929 22.0503 63.1213 20.8787L44.0294 1.7868C42.8579 0.615223 40.9584 0.615223 39.7868 1.7868C38.6152 2.95837 38.6152 4.85786 39.7868 6.02944L56.7574 23L39.7868 39.9706C38.6152 41.1421 38.6152 43.0416 39.7868 44.2132C40.9584 45.3848 42.8579 45.3848 44.0294 44.2132L63.1213 25.1213ZM3 26L61 26V20L3 20V26Z"
                        fill="#323B4B"
                        fill-opacity="0.08"
                      />
                    </svg>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    );
  }
  return (
    <>
      <div
        ref={parentRef}
        style={{ height: containerHeight }}
        className={" overflow-hidden hidden md:block relative"}
      >
        <div
          style={{ height: containerHeight }}
          ref={childRef}
          onScroll={(e) => setScrollPercent(getPercentage(e.currentTarget))}
          className={
            "z-20 relative " +
            (scrollable ? "overflow-scroll" : "overflow-hidden")
          }
        >
          <div className={"h-[1900px] z-10 scroll-smooth "} />
        </div>
        <Wrapper
          id={"content-ref"}
          className={"bg-p2 absolute top-0 left-0 w-full"}
        >
          <Container className={"md:pt-24 py-12 md:pb-56"}>
            <div className={"mb-3 md:mb-7"}>
              <h2 className={"text-center"}>
                <BoldSplitTitle
                  bold={(text) => (
                    <Text
                      className={"text-4xl md:text-6xl gilroy-bold"}
                      text={text}
                    />
                  )}
                  normal={(text) => (
                    <Text
                      className={"text-4xl md:text-6xl gilroy-regular"}
                      text={text}
                    />
                  )}
                  text={title}
                />
              </h2>
            </div>
            <div className={"mb-3 md:mb-7  text-center"}>
              <Text
                className={"text-lg md:text-2xl opacity-50 gilroy-regular"}
                text={subtitle}
              />
            </div>
            <div className={"flex mt-24 md:mt-48 gap-14 md:gap-28 lg:gap-56"}>
              <div
                className={
                  "flex flex-wrap md:flex-nowrap gap-10 justify-between w-full"
                }
              >
                <div className={"w-[60%] sm:w-[50%] "}>
                  <img
                    key={step.logo}
                    className={"slide-left"}
                    width={400}
                    src={step.logo}
                    alt={step.title}
                  />
                </div>
                <div className={"flex flex-col gap-2 md:gap-7 max-w-lg"}>
                  <div
                    className={"h-4 w-48 bg-[#F2F3F7] rounded-full relative"}
                  >
                    <div
                      style={{ width: `${scrollPercent * 100}%` }}
                      className={
                        "h-4 z-10 absolute top-0 left-0 bg-p1 rounded-full"
                      }
                    />
                  </div>
                  <div>
                    <div
                      className={"my-3 md:my-0 slide-left leading-normal"}
                      key={stepIndex}
                    >
                      <Text
                        text={"0" + (stepIndex + 1).toString()}
                        className={
                          "gilroy-bold text-6xl md:text-7xl leading-normal text-[#F1F3F6]"
                        }
                      />
                    </div>
                    <div key={step.title} className={"slide-left mt-4"}>
                      <BoldSplitTitle
                        bold={(text) => (
                          <Text
                            text={text}
                            className={"gilroy-bold text-3xl md:text-5xl"}
                          />
                        )}
                        normal={(text) => (
                          <Text
                            text={text}
                            className={"gilroy-regular text-3xl md:text-5xl"}
                          />
                        )}
                        text={step.title}
                      />
                    </div>
                  </div>
                  <div
                    key={step.body}
                    className={"text-justify md:text-left slide-left"}
                  >
                    <Text
                      text={step.body}
                      className={"font-inter md:text-lg text-body2"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Wrapper>
      </div>
      <div className={" overflow-hidden md:hidden relative"}>
        <Wrapper className={"bg-p2 px-4 xl:px-0"}>
          <Container className={"md:pt-24 py-12 md:pb-56"}>
            <div className={"mb-3 md:mb-7"}>
              <h2>
                <BoldSplitTitle
                  bold={(text) => (
                    <Text
                      className={"text-4xl md:text-6xl gilroy-bold"}
                      text={text}
                    />
                  )}
                  normal={(text) => (
                    <Text
                      className={"text-4xl md:text-6xl gilroy-regular"}
                      text={text}
                    />
                  )}
                  text={title}
                />
              </h2>
            </div>
            <div className={"mb-3 md:mb-7 text-justify md:text-left"}>
              <Text
                className={"text-lg md:text-2xl opacity-50 gilroy-regular"}
                text={subtitle}
              />
            </div>
            <div className={"flex flex-col mt-24 gap-14"}>
              {steps.map((step, i) => (
                <div
                  className={
                    "flex flex-wrap md:flex-nowrap gap-10 justify-between"
                  }
                  key={i}
                >
                  <img
                    className={"max-w-[60%] sm:max-w-[50%]"}
                    width={400}
                    src={step.logo}
                    alt={step.title}
                  />
                  <div className={"flex flex-col gap-2 md:gap-7 max-w-lg"}>
                    <div
                      className={"h-4 w-48 bg-[#F2F3F7] rounded-full relative"}
                    >
                      <div
                        style={{ width: `${((i + 1) * 100) / steps.length}%` }}
                        className={
                          "h-4 z-10 absolute top-0 left-0 bg-p1 rounded-full"
                        }
                      />
                    </div>
                    <div>
                      <div className={"my-3 md:my-0"}>
                        <Text
                          text={"0" + (i + 1).toString()}
                          className={
                            "gilroy-bold text-6xl md:text-7xl text-[#F1F3F6]"
                          }
                        />
                      </div>
                      <div>
                        <BoldSplitTitle
                          bold={(text) => (
                            <Text
                              text={text}
                              className={"gilroy-bold text-3xl md:text-5xl"}
                            />
                          )}
                          normal={(text) => (
                            <Text
                              text={text}
                              className={"gilroy-regular text-3xl md:text-5xl"}
                            />
                          )}
                          text={step.title}
                        />
                      </div>
                    </div>
                    <div className={"text-justify md:text-left"}>
                      <Text
                        text={step.body}
                        className={"font-inter md:text-lg text-body2"}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </Wrapper>
      </div>
    </>
  );
}

export default Steps;
